import { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import hamburger from "../../assets/hamburger.png";
import logo from "../../assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

export default function Header({ location }) {
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div>
      {location === "homepage" ? (
        <div>
          <div className="navbar-alignment">
            <img
              src={logo}
              onClick={() => {
                navigate("/");
                setIsNavExpanded(false);
              }}
              className="desktop mobile link"
              id="mobile"
            />
            <div className="hover">
              <img
                src={hamburger}
                className="hamburger blue"
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              />
            </div>
          </div>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul className="headertext">
              <li>
                <a
                  className="link"
                  href="/#about"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/#work"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  WORK
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/#testimonials"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  TESTIMONIALS
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/#contact"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <>
        <div className="navbar-alignment">
          <img
            src={logo}
            onClick={() => navigate("/")}
            className="desktop mobile link"
            id="mobile"
          />
          <div className="hover">
            <img
              src={hamburger}
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            />
          </div>
          </div>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul className="nav-items headertext">
              <li>
                <a
                  className="link"
                  href="/about"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/work"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  WORK
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/testimonials"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  TESTIMONIALS
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="/contact"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
