import "./Footer.css";
import { useNavigate, useParams } from "react-router-dom";


export default function Footer({ location }) {
  const navigate = useNavigate();

  return (
    <>
      {location === "homepage" ? (
        <div className="footertext">
          <a className="link" href="/#about">
            about
          </a>
          <a className="link" href="/#work">
            work
          </a>
          {/* <div className="link" onClick={() => navigate("/testimonials")}>testimonials</div> */}
          <a className="link" href="#testimonials">
            testimonials
          </a>
          <a className="link" href="/#contact">
            contact
          </a>
        </div>
      ) : (
        <div className="footertext">
          <a onClick={() => navigate("/about")} className="link">
            about
          </a>
          <a onClick={() => navigate("/work")} className="link">
            work
          </a>
          {/* <div className="link" onClick={() => navigate("/testimonials")}>testimonials</div> */}
          <a className="link" onClick={() => navigate("/testimonials")}>
            testimonials
          </a>
          <a className="link" onClick={() => navigate("/contact")}>
            contact
          </a>
        </div>
      )}
    </>
  );
}
