import Header from "../../components/Header/Header";
import Reviews from "../../components/Testimonials/Reviews";
import Testimonials from "../../components/Testimonials/Testimonials";
import "./Testimonials-Page.css";

export default function TestimonialsPage({ location }) {
  return (
    <div>
      <Header />

      <div className="testimonials-page">
        <div>
          <div className="header-testimonials top-padding font-special">Reviews</div>
          <Reviews />
        </div>
        <div>
          <div className="header-testimonials font-special testimonials-padding">Testimonials</div>
          <Testimonials />
        </div>
      </div>
    </div>
  );
}
