import "./Contact-Components.css";

export default function ContactCopy() {
  return (
    <div className="contact-copy">
      <div className="copy-para font">
        How can we work together? Have any questions? Find me on the platforms
        below, or send me a message below!
      </div>
    </div>
  );
}
