import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./Work-Page.css";
import pdf from "../../assets/8-5-23-joshuanhsu-resume.pdf";

import images from "../../components/Work/showimages.jsx";
import { Component, useEffect } from "react";
export default function WorkPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="header-green top-padding cursor selected-work">Selected Work</div>
      <div className="workpage-text">
        <p>Find a selection of my work below, and click to learn more.</p>
        <p> View my resume <a className="resumehere" href = {pdf} target = "_blank">here</a>.</p>
        

      </div>
      <div className="allshows">
        {images.covers.map((img, index) =>
          index === 0 ? (
            <div
              className="show-images"
              onClick={() => navigate(`/work/${img.title}`)}
            >
              <div className="all-shows-title font-special cursor">{img.title.replace(/-/g, " ")}</div>
              <img
                key={index}
                src={img.url}
                alt={img.alt}
                className="showimage cursor"
              />
              <div className="show-images-credit">
                show photography by{" "}
                <a
                  href="https://www.frenchsfotos.com/"
                  target="_blank"
                  className="link"
                >
                  Noli French
                </a>
              </div>
            </div>
          ) : (
            <div
              className="show-images"
              onClick={() => navigate(`/work/${img.title}`)}
            >
              <hr className="line" />

              <div className="all-shows-title font-special cursor">
                {img.title.replace(/-/g, " ")}
              </div>
              <img
                key={index}
                src={img.url}
                alt={img.alt}
                className="showimage cursor"
              />
              <div>
                {img.title === "Straight-White-Men" ? (
                  <div className="show-images">
                    show photography by{" "}
                    <a
                      href="https://www.frenchsfotos.com/"
                      target="_blank"
                      className="link"
                    >
                      Noli French
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}
