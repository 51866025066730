import Home from "./pages/Home/Home.jsx";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import TestimonialsPage from "./pages/Testimonials-Page/Testmonials-Page.jsx";
import Work from "./components/Work/Work.jsx";
import WorkPage from "./pages/Work/Work-Page.jsx";
import Slideshow from "./components/Slideshow/Slideshow.jsx";
import AboutPage from "./pages/About-Page/About-Page.jsx";
import ContactPage from "./pages/Contact-Page/Contact-Page.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Header from "./components/Header/Header.jsx";


function App() {

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/work/White" element={<Work location="White"/>} />
        <Route path="/work/Some-Explicit-Polaroids" element={<Work location="Some-Explicit-Polaroids"/>} />
        <Route path="/work/Medea" element={<Work location="Medea"/>} />
        <Route path="/work/How-I-Learned-To-Drive" element={<Work location="How-I-Learned-To-Drive"/>} />
        <Route path="/work/Talk-Radio" element={<Work location="Talk-Radio"/>} />
        <Route path="/work/Straight-White-Men" element={<Work location="Straight-White-Men"/>} />
        <Route path="/work/Stop-Kiss" element={<Work location="Stop-Kiss"/>} />
        <Route path="/work/lysistrata" element={<Work location="lysistrata"/>} />
        <Route path="/work/Caucasian-Chalk-Circle" element={<Work location="Caucasian-Chalk-Circle"/>} />
        <Route path="/slidetest" element={<Slideshow />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
