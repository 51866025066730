import { ContactUs } from "../../components/Contact/Contact-Form";
import ContactCopy from "../../components/Contact/Contact-Copy";
import Header from "../../components/Header/Header";

export default function ContactPage() {
  return (
    <div>
      <Header />

      <div className="header-green top-padding" id="contact">
        Get In Touch
      </div>
      <div className="homepage-bodycontent">
        <ContactCopy />
        <ContactUs />
      </div>
    </div>
  );
}
