import homepageBlur from "../../assets/homepage-blur.png";

export default function AboutWork() {
  return (
    <div className="aboutWork-container">
      <div className="aboutwork">
        <div className="homefont">
          I believe in creating and facilitating theatrical experiences that are
        </div>
        <div className="font-special homepage-special">
          <div className="about-work-one">unapologetically relevant,</div>
          <div className="about-work-two">unabashedly visceral,</div>
          <div className="about-work-three">and joyfully dynamic.</div>
        </div>
        <div className="homefont">
          As a director, I have always been intrigued by telling (or re-telling)
          stories that explore and confront the questions of race, gender, and
          sexuality and their relationship to power. As a designer, I believe in
          creating integrated, immersive worlds that are essential to
          storytelling as setting the mood. As a producer and production
          consultant, I am always looking to collaborate with and empower
          diverse creators and performers.
        </div>
      </div>
      <div className="aboutpage-img">
        <img src={homepageBlur} />
      </div>
    </div>
  );
}
