import { useEffect } from "react";
import "./Testimonials.css";


export default function Testimonials({ location }) {
  let testimonials = [
    {
      text1:
        "You know you're working with a real director when they ask you to arm wrestle your scene partner to find the relationship and stakes of the scene. I was twenty and never forgot that experience.",
      special: " Josh is the kind of director every actor wants to work with: ",
      text2:
        "he gets to the meat of it, he forgot the words for a moment and gave me the ability to feel things as me, in the moment, so that I could find my way in. We were in our early twenties! Come on, that speaks volumes to Josh's understanding of text and the actors' process. Josh's heart is in it. He is a very talented director.",
      signoff: "- Stephen Balantzian, Broadway Actor, Some Explicit Polaroids",
      key: 1,
    },
    {
      special: "Joshua Hsu is a dream director ",
      text2:
        "from a producer's perspective.  He has a clear-cut vision for every project he works on - he tells you what he needs, and if you can't get it for him, he'll find a way to work around it. Josh is never discouraged when things go wrong, and displays nothing but a positive attitude.  He is thorough, calm, concise, and is not afraid to dream big (seriously, he can make ANYTHING work)!  I have greatly enjoyed my experiences working with Josh, and I will absolutely be working with him in the future…and you should be, too! ",
      signoff: "- Emily Clark, Artistic Director, Unknown Artists",
      key: 2,
    },
    {
      text1:
        "As a playwright, I like to work with directors who balance bringing their own aesthetic to a piece while staying true to the text. While working on i r l (in real life), Josh did a tremendous job with this, finding ways to to bring the words and the world of the play to life in inventive ways I wouldn't have thought of.",
      special: " He has everything you want in a director - ",
      text2:
        "Impeccable taste, vision, and a collaborative spirit that allows the artists working for him to flourish.",
      signoff: "- Rick Robinson, Playwright",
      key: 3,
    },
    {
      text1:
        "I have had the privilege of working with Joshua Hsu on multiple projects over the years, and",
      special: " I've always been impressed with his artistic vision, ",
      text2:
        "work ethic, and the consistent high quality of his directing. I found collaborating with him recently during the writing process of Lysistrata: A Reimagining  to be a pleasure because he immediately understood the play's mixture of humor, politics, and sexuality. He contributed a wealth of exciting ideas which helped to refine the plot, structure, and tone of the piece. His direction brought the play to life, balancing its mixture of classical language and modern allusions, and incorporating visual imagery throughout the performance to great effect.",
      signoff: "- Alicia Tycer, M.F.A., Ph.D Playwright",
      key: 4,
    },
    {
      text1:
        "Working with Joshua Hsu on How I Learned to Drive was a great experience. Josh put in a large amount of research and work prior to rehearsal and knew exactly what he was doing, which made my job as an actor that much easier. His vision for the piece as a whole was so specific; it gave us a clear focus for working on individual scenes, while still allowing us to make our own discoveries with our characters.",
      special: " I would absolutely love to work with him again!",
      signoff: "- Jocelyn Hall, Actor",
      key: 5,
    },
    {
      text1:
        "I've known Josh for nearly fifteen years as a fellow theatre artist, friend and director. Most recently he directed me in the title role of Lysistrata, A Re- Imagining. ",
      special: "He is a highly creative, thoughtful director",
      text2:
        " who pushes his ensemble to achieve deep, honest connection with each other and the material. He chooses provocative work that is entirely relevant for a modern audience. I'm so thankful for our visionary collaboration on Lyisistrata!",
      signoff: "- Cady Zuckerman, Actor",
      key: 6,
    },
        {
      text1:
        "",
      special: "Joshua Hsu has a remarkable attentive artistic vision in all he does.",
      text2:
        " From sound design that makes us reflect and sit in discomfort, to directing that motivates an actor's movement into fulfilled/unfulfilled desires, human mundanities, and humorous awkward moments. I have had the privilege of working with him as a fellow collaborator, an actor, and a co-director. You don't want to miss any opportunity to work with him! ",
      signoff: "- Maya Williams, MSW & MFA",
    },
    {
      text1:
        "",
      special: "Josh is a producer's dream.",
      text2:
        " He directed a one-act as part of a larger one-act festival I produced.  I gave him strict set and lighting limitations, a minuscule budget, and a difficult performance space. He gave me a polished, haunting piece of art. The actors in our company were thrilled to work with him, the playwright was pleased with the work, and our audiences raved. Not only was the end product something we were all proud of, but working with him on the day-to-day minutiae of theater was a joy.  He was communicative, knew which battles to pick, and always treated me as his partner, not his enemy.  He's a rare find!",
      signoff: "- Jeni Incontro, Producer, Unknown Artists",
    },
    {
      text1:
        "I worked with Josh on Lysistrata A Re-Imagining, and Snake Skin Suit. ",
      special: "Josh is an ambitious director with the vision to match.",
      text2:
        " He likes to work outside the box with his design, but at the same time keeps the acting grounded.  In terms of the relationship between director and designer he allowed me my creative freedom for the lighting, but made sure my design tied in with the overall production concept.  Working these two shows makes me look forward to working with Josh in the future.",
      signoff: "- Nick Saiki, Lighting Designer",
    },
    {
      text1:
        "",
      special: "Josh is a talented, detailed, hard working director.",
      text2:
        " He loves theatre and is involved in every aspect. Josh stepped in last minute amidst an already busy schedule, to direct a short during our shorts play festival. He then went on to direct two full lengths back to back for us at Red Brick Road.",
      signoff: "- Bryan Keith, Red Brick Road",
    },
    {
      text1:
        "You know you're working with a real director when they ask you to arm wrestle your scene partner to find the relationship and stakes of the scene. I was twenty and never forgot that experience. ",
      special: "Josh is the kind of director every actor wants to work with:",
      text2:
        " he gets to the meat of it, he forgot the words for a moment and gave me the ability to feel things as me, in the moment, so that I could find my way in. We were in our early twenties! Come on, that speaks volumes to Josh's understanding of text and the actors' process. Josh's heart is in it. He is a very talented director.",
      signoff: "- Stephen Balantzian, Broadway Actor, Some Explicit Polaroids",
    },
    // {
    //   text1:
    //     "",
    //   special: "",
    //   text2:
    //     "",
    //   signoff: "- ",
    // },
  ];

  let number = Math.floor(Math.random() * (testimonials.length - 1));
  console.log(number)
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      {location === "homepage" ? (
        <div key={testimonials[number].key} className="reviews-testmonials homefont">
          <div>
            {testimonials[number].text1}
            <span className="reviews-special">{testimonials[number].special}</span>
            {testimonials[number].text2}
          </div>
          <div className="review-signoff">{testimonials[number].signoff}</div>
        </div>
      ) : (
        <div>
          {testimonials.map((testimonial) => (
            <div key={testimonial.key} className="reviews-testmonials">
              <div>
                {testimonial.text1}
                <span className="reviews-special">{testimonial.special}</span>
                {testimonial.text2}
              </div>
              <div className="review-signoff">{testimonial.signoff}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
