import "./Testimonials.css";

export default function Reviews({ location }) {
  let reviews = [
    {
      text1:
        "Lucid by Proxy once again shows its vitality with a new offering that succeeds mostly on the quirkiness and precision of live actors and computer-generated characters together onstage. And for this feat alone, this play should find a young audience. Three online gaming enthusiasts (David Nett, JoAnna Senatore and James Paul Xavier) decide to meet in person for the first time at a national convention for their favorite game, Wizards Exile, only to discover that the fourth of their legion will not be able to make it. Thus begins a quest in real life, cross-country, to find her. The lunacy of this action is paralleled by their online characters moving through a magical landscape projected onto a screen upstage. Naturally, the gamers subsequently deal with the chasmic gap between the reality of the game and the reality of life.",
      special:
        " Director Joshua Hsu captures the duality of the characters beautifully in the physical space...",
      signoff: "- Luis Reyes, LA Weekly",
      key: 1,
    },
    {
      special: "…Kudos to Joshua Hsu ",
      text2:
        "for his delicate treatment of a provocative and stirring story! Timing and staging were impeccably choreographed. Using minimal props, a riser and few chairs and a table, director Joshua manages to convey the flashbacks and forwards, subtly alerting the audience to what is going on during the driving lessons…Definitely worth seeing, followed by a bottle of wine and conversation… ",
      signoff: "- Diane Williams, Nohoartsdistrict.com",
      key: 2,
    },
    {
      text1: "Director Joshua N. Hsu keeps the action ",
      special: "playful but increasingly poignant,",
      text2: " filling the stage with movement and music as the characters within the able cast evince a loving, if volatile, chemistry.",
      signoff: "- Steve Feeney ",
      key: 4
    },
    {
      text1:
        "Slipping off the comic masks he's worn with subtlety in past productions, Joshua Hsu's Mark Ravenhill's Some Explicit Polaroids at Parker Theater reveals the impassioned humanist behind the acting fool…",
      special:
        " Hsu's actors work from the gut, and their verissimo performances are riveting.",
      text2:
        " …Burdened like Atlas with the world, members move blocks in semi-darkness to create setting. Hsu and his light designer project object and visions against the back of the stage, objectifying character's emotions, while light and sound- there was much rain on this human tragic-comedy-further shaped subliminals.",
      signoff: "- Kitty Montgomery, Daily Freeman",
      key: 3,
    },
    {
      text1: "Co-directors Maya Williams and Joshua N Hsu have put together",
      special: " an excellent production ",
      text2: "to explore the author's insights into the impact of shifts in notions of identity and privilege in today's world.",
      signoff: "- Steve Feeney ",
      key: 4
    },
    {
      text1: "The comedic drama is onstage now at Mad Horse Theatre Company, under the ",
      special: "agile direction of Joshua N. Hsu.",
      text2: " Raucous and profane but also startlingly nuanced, “Straight White Men” might not be quite what the title leads you to expect.” ",
      signoff: "- Portland Phoenix ",
      key: 4
    },
  ];

  let number = Math.floor(Math.random() * (reviews.length - 1));

  return (
    <>
      {location === "homepage" ? (
        <div key={reviews[number].key} className="reviews-testmonials homefont">
          <div>
            {reviews[number].text1}
          <span className="reviews-special">{reviews[number].special}</span>
            {reviews[number].text2}
          </div>
          <div className="review-signoff">{reviews[number].signoff}</div>
        </div>
      ) : (
        <div id="reviews">
          {reviews.map((review) => (
            <div key={review.key} className="reviews-testmonials">
              <div>
                {review.text1}
               <span className="reviews-special">{review.special}</span>
                {review.text2}
              </div>
              <div className="review-signoff">{review.signoff}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
