import AboutJosh from "../../components/About/About-Josh";
import AboutWork from "../../components/About/About-Work";
import Header from "../../components/Header/Header";

export default function AboutPage() {
  return (
    <div>
      <Header />
      <div className="homepage-bodycontent">
        <AboutJosh />
        <AboutWork />
      </div>
    </div>
  );
}
