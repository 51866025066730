import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact-Components.css";

export const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitted(true);
    emailjs
      .sendForm(
        'default_service',
        'contactForm',
        form.current,
        {
          publicKey: 'BKDIYa75XONvF0q6p',
        }
      )
      .then(
        () => {
          console.log('success!');
        },
        (error) => {
          console.log('failed...',error.text);
        }
      );
  };

  useEffect(() => {
    setSubmitted(false);
  }, []);


  return submitted ? (
    <div>
      <div className="thankyou-form">
        <p className="font-special thankyou">Thank you!</p>
        <p className="intouch font">I will be in touch shortly.</p>
      </div>
    </div>
  ) : (
    <>
      <form className="contact-form" ref={form} onSubmit={sendEmail}>
        <label className="font">Name</label>
        <input type="text" name="user_name" required />
        <label className="font">Email</label>
        <input type="email" name="user_email" required />
        <label className="font">Message</label>
        <textarea name="message" required />
        <input
          className="submit-button font-special"
          type="submit"
          value="SUBMIT"
        />
      </form>
    </>
  );
};
