import "./About.css";
import placeholder from "../../assets/homepage-blur.png"
import josh from "../../assets/Josh-headshot.jpg"


export default function AboutJosh() {
  return (
    <div className="aboutJosh-container" id="about">
      <div className="aboutpage-img">
        <img src={josh} />
        <div className="photocredit">photography by <a href="https://www.instagram.com/blacklodge.portland/" target="_blank" className="link">Tadin Brego</a></div>
      </div>
      <div className="about-josh">
        <p className="homefont hello"><span>Hello there! I'm&nbsp;</span><span className="font-special color-reddish homepage-special"> Joshua N Hsu </span><span>&nbsp;(pronounced 'shoe').</span></p>
        <p className="homefont">
          Born and raised in New York, I'm a second-generation Chinese-American
          storyteller. In my formative years, I attended Manhattan School of
          Music, learning music theory and composition. I enrolled in college at
          the State University of the New York at New Paltz, where I earned my
          B.A. in both Acting and Lighting Design. Later, I received my M.F.A.
          in Directing from the University of California- Irvine.
        </p>
        <p className="homefont">
          Since then, I've been blessed to work in so many different capacities
          in the performing arts, tv, and film. As a director, designer,
          composer, writer, producer, and production consultant, I have worked
          all over the United States.
        </p>
        <p className="homefont">
          Beyond the arts, I am an advocate for higher education, mentoring and
          working with students of all ages and all backgrounds to achieve their
          dreams. I am currently based out of Portland, Maine with my
          ever-supportive wife, our two children, and our two dogs.
        </p>
      </div>
    </div>
  );
}
