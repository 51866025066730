const images = {
  white: [
    {
      url: "/images/white/white1.jpg",
      alt: "a photo from white",
      title: "White",
    },
    {
      url: "/images/white/white2.jpg",
      alt: "a photo from white",
      title: "White",
    },
    {
      url: "/images/white/white3.jpg",
      alt: "a photo from white",
      title: "White",
    },
    {
      url: "/images/white/white4.jpg",
      alt: "a photo from white",
      title: "White",
    },
  ],
  polaroids: [
    {
      url: "/images/polaroids/polaroids1.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids2.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids3.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids4.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids5.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids6.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids7.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids8.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids9.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
    {
      url: "/images/polaroids/polaroids10.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some Explicit Polaroids",
    },
  ],
  HILTD: [
    {
      url: "/images/HILTD/HILTD1.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD2.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD3.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD4.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD5.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD6.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD7.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD8.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD9.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD10.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD11.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
    {
      url: "/images/HILTD/HILTD12.jpg",
      alt: "a photo from how i learned to drive",
      title: "How I Learned To Drive",
    },
  ],
  medea: [
    {
      url: "/images/medea/medea1.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea2.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea3.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea4.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea5.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea6.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea8.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea10.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea11.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea12.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea13.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea14.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea51.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea16.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea17.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea18.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea19.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea21.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea22.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/medea/medea23.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
  ],
  talkradio: [
    {
      url: "/images/talkradio/talkradio1.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/talkradio/talkradio2.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/talkradio/talkradio3.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/talkradio/talkradio4.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/talkradio/talkradio5.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/talkradio/talkradio6.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
  ],
  whitemen: [
    {
      url: "/images/whitemen/swm1.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm2.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm3.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm4.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm5.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm6.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm7.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm8.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm9.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm10.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm11.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm12.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm13.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm14.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm15.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm16.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm17.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm18.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm19.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/whitemen/swm20.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },    
  ],

  chalk: [
    {
      url: "/images/chalk/chalk1.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk2.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk3.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk4.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk5.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk6.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk7.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
    {
      url: "/images/chalk/chalk8.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
  ],
  lysistrata: [
    {
      url: "/images/lysistrata/lysistrata1.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata13.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata3.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata4.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata5.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata6.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata7.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata8.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata9.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata10.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata11.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata12.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/lysistrata/lysistrata2.jpg",
      alt: "a photo from Lysistrata",
      title: "lysistrata",
    },

  ],
  stopkiss: [
    {
      url: "/images/stopkiss/stopkiss1.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss2.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss3.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss4.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss5.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss6.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss7.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss8.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss9.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss10.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
    {
      url: "/images/stopkiss/stopkiss11.jpg",
      alt: "a photo from stopkiss",
      title: "Stop Kiss",
    },
  ],
  covers: [
    {
      url: "/images/coverwhite.png",
      alt: "a photo from white",
      title: "White",
    },
    {
      url: "/images/coverpolaroids.png",
      alt: "a photo from some explicit polaroids",
      title: "Some-Explicit-Polaroids",
    },
    {
      url: "/images/coverHILTD.png",
      alt: "a photo from how i learned to drive",
      title: "How-I-Learned-To-Drive",
    },
    {
      url: "/images/covermedea.png",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/covertalkradio.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/coverwhitemen1.png",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/coverstopkiss.png",
      alt: "a photo from stop kiss",
      title: "Stop-Kiss",
    },
    {
      url: "/images/coverlysistrata.png",
      alt: "a photo from lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/coverchalk.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
  ],
  nodoodles: [
    {
      url: "/images/nodoodle/coverwhite.jpg",
      alt: "a photo from white",
      title: "White",
    },
    {
      url: "/images/nodoodle/coverpolaroids.jpg",
      alt: "a photo from some explicit polaroids",
      title: "Some-Explicit-Polaroids",
    },
    {
      url: "/images/nodoodle/coverHILTD.jpg",
      alt: "a photo from how i learned to drive",
      title: "How-I-Learned-To-Drive",
    },
    {
      url: "/images/nodoodle/covermedea.jpg",
      alt: "a photo from medea",
      title: "Medea",
    },
    {
      url: "/images/nodoodle/covertalkradio.jpg",
      alt: "a photo from talk radio",
      title: "Talk-Radio",
    },
    {
      url: "/images/nodoodle/coverwhitemen.jpg",
      alt: "a photo from straight white men",
      title: "Straight-White-Men",
    },
    {
      url: "/images/nodoodle/coverstopkiss.jpg",
      alt: "a photo from stop kiss",
      title: "Stop-Kiss",
    },
    {
      url: "/images/nodoodle/coverlysistrata.jpg",
      alt: "a photo from lysistrata",
      title: "lysistrata",
    },
    {
      url: "/images/nodoodle/coverchalk.jpg",
      alt: "a photo from caucasian chalk circle",
      title: "Caucasian-Chalk-Circle",
    },
  ],
};

export default images;
