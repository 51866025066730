import ImageSlider from "./Image-Slider.jsx";
import images from "../Work/showimages.jsx";
import "./Slideshow.css";

export default function Slideshow() {
  const slides = images.nodoodles;

  return (
    <div className="slideshow-container">
      <ImageSlider slides={slides} />
    </div>
  );
}
