import { useEffect, useState } from "react";

export default function ImageSlider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imgUrl = "work/" + slides[currentIndex].title


  const slideStyles = {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="sliderStyles">
      <div className="left-arrow" onClick={goToPrevious}>
        ←
      </div>
      <div className="right-arrow" onClick={goToNext}>
        →
      </div>
      <div
        style={slideStyles}
      ></div>
      <a href={imgUrl} className="visible font-special">{slides[currentIndex].title.replace(/-/g," ")}</a>
    </div>
  );
}
