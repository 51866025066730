import "./Work.css";
import Header from "../Header/Header";
import images from "./showimages.jsx";
import { useEffect } from "react";


export default function Shows({ location }) {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
    <Header />
      {(() => {
        if (location === "White") {
          return (
            <>
              <div className="header-green">White</div>
              <div className="show-images">by James Ijames</div>
              <div className="show-images">
                {images.white.map((img, index) => (
                  <>
                    <div>{img.blurb}</div>
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  </>
                ))}
              </div>
            </>
          );
        } else if (location === "Some-Explicit-Polaroids") {
          return (
            <>
              <div className="header-green">Some Explicit Polaroids</div>
              <div className="show-images">
                <div className="show-images">by Mark Ravenhill</div>
                {images.polaroids.map((img, index) => (
                  <img
                    key={index}
                    src={img.url}
                    alt={img.alt}
                    className="showimage"
                  />
                ))}
              </div>
            </>
          );
        } else if (location === "Medea") {
          return (
            <>
              <div className="header-green">Medea</div>
              <div className="show-images">
                <div className="show-images">by Euripides</div>
                {images.medea.map((img, index) => (
                  <img
                    key={index}
                    src={img.url}
                    alt={img.alt}
                    className="showimage"
                  />
                ))}
              </div>
            </>
          );
        } else if (location === "How-I-Learned-To-Drive") {
          return (
            <>
              <div className="header-green">How I Learned To Drive</div>
              <div className="show-images">
                <div className="show-images">by Paula Vogel</div>
                {images.HILTD.map((img, index) => (
                  <img
                    key={index}
                    src={img.url}
                    alt={img.alt}
                    className="showimage"
                  />
                ))}
              </div>
            </>
          );
        } else if (location === "Talk-Radio") {
            return (
              <>
                <div className="header-green">Talk Radio</div>
                <div className="show-images">
                  <div className="show-images">by Eric Bogosian</div>
                  {images.talkradio.map((img, index) => (
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  ))}
                </div>
              </>
            );
          } else if (location === "Straight-White-Men") {
            return (
              <>
                <div className="header-green">Straight White Men</div>
                <div className="show-images">by Young Jean Lee</div>
                <div className="show-images">
                  {images.whitemen.map((img, index) => (
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  ))}
                </div>
              </>
            );
          } else if (location === "Caucasian-Chalk-Circle") {
            return (
              <>
                <div className="header-green">Caucasian Chalk Circle</div>
                <div className="show-images">
                  <div className="show-images">by Bertolt Brecht</div>
                  {images.chalk.map((img, index) => (
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  ))}
                </div>
              </>
            );
          } else if (location === "Stop-Kiss") {
            return (
              <>
                <div className="header-green">Stop Kiss</div>
                <div className="show-images">
                  <div className="show-images">by Diana Son</div>
                  {images.stopkiss.map((img, index) => (
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  ))}
                </div>
              </>
            );
          } else if (location === "lysistrata") {
            return (
              <>
                <div className="header-green">Lysistrata</div>
                <div className="show-images">
                  <div className="show-images">by Alicia Tycer</div>
                  {images.lysistrata.map((img, index) => (
                    <img
                      key={index}
                      src={img.url}
                      alt={img.alt}
                      className="showimage"
                    />
                  ))}
                </div>
              </>
            );
          } 
      })()}
    </>
  );
}


// } else if (location === "***SHOWURL****") {
//   return (
//     <>
//       <div className="header-green">***SHOWTITLE****</div>
//       <div className="show-images">
//         {images.***SHOW****.map((img, index) => (
//           <img
//             key={index}
//             src={img.url}
//             alt={img.alt}
//             className="showimage"
//           />
//         ))}
//       </div>
//     </>
//   );
// }