import AboutJosh from "../../components/About/About-Josh";
import AboutWork from "../../components/About/About-Work";
import Header from "../../components/Header/Header";
import Reviews from "../../components/Testimonials/Reviews";
import Testimonials from "../../components/Testimonials/Testimonials";
import { useNavigate } from "react-router-dom";


import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";

import "./Home.css";
import Slideshow from "../../components/Slideshow/Slideshow";
import ContactCopy from "../../components/Contact/Contact-Copy";
import { ContactUs } from "../../components/Contact/Contact-Form";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div>
      <Header location={"homepage"} />
      <div className="homepage-bodycontent">
        <AboutJosh />
        <div>
          <div className="header-green">About My Work</div>
          <AboutWork />
        </div>
        <div>
          <div id="work" className="header-green">
            My Work
          </div>
          <Slideshow />
          <div
            className="link read-more font-special"
            onClick={() => navigate("/work")}
          >
            - View All -
          </div>
        </div>
        <div>
          <div className="header-green" id="testimonials" location={"homepage"}>
            Testimonials
          </div>
          <Reviews location={"homepage"} />
          <Testimonials location={"homepage"} />
          <div
            className="link read-more font-special"
            onClick={() => navigate("/testimonials")}
            >
            - Read More -
          </div>
        </div>
        <div>
          <div className="header-green" id="contact">
            Get In Touch
          </div>
          <div className="icons">
           <a href="https://www.facebook.com/profile.php?id=61560773484200"> <Facebook className="individual-icon"/></a>
           <a href="https://www.instagram.com/hsunjoshua"><Instagram className="individual-icon" /></a>
          </div>

          <ContactCopy />
          <ContactUs />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
